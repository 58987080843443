.icon_section {
  display: flex;
  width: 100%;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  margin: 5px 5px 20px 5px;
}
.icon_image {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: #f0f0f0;
}
.title {
  background-color: #f0f0f0;
  padding: 5px 0;
  width: 45px;
  margin-top: 10px;
}
.image_container {
  background-color: #f0f0f0;
  object-fit: contain;
  height: 500px;
  width: 100%;
}
.banner {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #f0f0f0;
}
@media only screen and (max-width: 1023px) {
  .banner {
    height: 50% !important;
  }
}
