img.banner_thumbnail {
  width: auto !important;
  object-fit: cover;
  height: auto !important;
}
.benefits {
  width: 100%;
  object-fit: contain;
  height: auto;
}
.banner_about {
  width: 100%;
  object-fit: contain;
  height: auto;
}
@media only screen and (max-width: 650px) {
  img.banner_thumbnail {
    width: 100% !important;
    height: 400px !important;
  }
}
